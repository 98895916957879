/*import 'bootstrap/dist/css/bootstrap.css';*/
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';


import createApp from '@shopify/app-bridge';
import { Redirect as ShopifyRedirect } from '@shopify/app-bridge/actions';
import { getSessionToken } from "@shopify/app-bridge-utils";


async function CheckAuth(response) {

    var apptoken = response.appToken;

    if (apptoken && window.top !== window.self) {

        response.appToken = apptoken;
        return response;
    }
    var x = response.redirectToApp;

    var y = response.redirectShopifyToBill;


    var apiKey = response.appKey;
    var shopOrigin = response.shopOrigin;
    var permissionUrl = response.permissionUrl;

    var host = btoa(shopOrigin + '/admin');


    if (window.top === window.self) {
        if (x) {
            console.log(x);
            window.location = x;
        } else if (y) {

            window.location.href = y;

        } else if (permissionUrl) {

            window.location.assign('https://' + shopOrigin + '/admin' + permissionUrl);
        }

        // If the current window is the 'child', change the parent's URL with Shopify App Bridge's Redirect action
    } else if (permissionUrl) {

        const app = createApp({
            apiKey: apiKey,
            host: host,
        });

        ShopifyRedirect.create(app).dispatch(ShopifyRedirect.Action.ADMIN_PATH, permissionUrl);
    } else if (y) {
        console.log(y);
        const app = createApp({
            apiKey: apiKey,
            host: host,
        });

        ShopifyRedirect.create(app).dispatch(ShopifyRedirect.Action.REMOTE, y);

    };



    return null;

}

async function CheckAuthDev(response) {

    var apptoken = response.appToken;

    response.appToken = apptoken;
    return response;


}

async function getAuth() {
     

    const query = new URLSearchParams(window.location.search);

    var host = btoa(query.get("shop") + '/admin');

    if (window.top !== window.self) {


        var responseGetapikey = await fetch(`authority/apikey`);
        var apikey = await responseGetapikey.text();

        const app = createApp({
            apiKey: apikey,
            host: host,
        });

        const sessionToken = await getSessionToken(app);

        query.set("sessiontoken", sessionToken);

    }  

    var response = await fetch(`authority/index?${query}`);
    var data = await response.json();


    // check if test envirment
    if (process.env.NODE_ENV === 'development') {

        return await CheckAuthDev(data);
    }

    return await CheckAuth(data);

    // return null;
}



getAuth().then(


    res => {

        if (res && res.appToken) {
            const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
            const rootElement = document.getElementById('root');


            ReactDOM.render(
                <BrowserRouter basename={baseUrl}>
                    <App apptoken={res.appToken} />
                </BrowserRouter>,
                rootElement);

            registerServiceWorker();
        }



    }


).catch(err => console.log(err));




