import React, { useCallback, useState, useEffect } from 'react';
import { Page, Card, Layout, DataTable, Badge, Stack, Thumbnail} from '@shopify/polaris';

import { Menu } from './Menu';

export function Dashboard(prop) {


    const [dashdata, setdashdata] = useState('');


    useEffect(() => {


        console.log('u----------------------called');
        let didCancel = false;

        var controller = new AbortController();
        var signal = controller.signal;

        const getSetting = () => {
            console.log('fetch getSetting');

            fetch(`dashboard/data?apptoken=${prop.apptoken}`, { signal: signal })
                .then((response) => response.json())
                .then((data) => {
                    if (!didCancel) { 
                        console.log("data");
                        console.log(data); 
                        setdashdata(data);
                         
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };


        getSetting();
        return () => {
            didCancel = true;
        };
    }, [prop])
     

    const initiallySortedRows = [
        []
    ];
    const rows = dashdata ? dashdata.recentlyAddedItems.map(function (obj) {
        return [<Thumbnail
            source={obj.imageUrl}
            alt={obj.productTitle}
        /> ,obj.productTitle, obj.createdAt];
    })
 : initiallySortedRows;

 


    return (
        <><Menu />
            <Page title="ダッシュボード">
                <Layout>
                    <Layout.Section>
                        <Card
                            secondaryFooterActions={[{ external:true, url:'https://eclab.dev/manual/favorite-items/', content: 'マニュアル' }]}
                            title={'使用中テーマ:' + (dashdata ? dashdata.mainThemeName : '')}
                            primaryFooterAction={{ url: '/themesetting', content: 'テーマ設定' }}
                        >
                             
                        </Card>




                        <Card title="最近お気に入りに追加された商品">
                            <DataTable
                                columnContentTypes={[
                                    'text',
                                    'text',
                                    'text'
                                ]}
                                headings={[
                                    'イメージ',
                                    '商品名',
                                    '追加された日時'
                                ]}
                                rows={rows} />
                        </Card>

                    </Layout.Section>

                </Layout>

            </Page></>
    );
 
}
