import React, { useState, useCallback, useEffect } from 'react';
import { Page, Banner, Card, Layout, Button, Heading, Select, SettingToggle, TextStyle, TextField} from '@shopify/polaris';

import { Menu } from './Menu';
import createApp from '@shopify/app-bridge';
import { Redirect as ShopifyRedirect } from '@shopify/app-bridge/actions';


export function ThemeSetting(prop) {
     
    const [pageconfig, setpageconfig] = useState({
        loading: false,
        config: null,
        processResult:{
            show: false,
            detail: {
            result: true,
            message: ""
            }
        }
      }
    );

    const { loading, config, processResult } = pageconfig;
    // select theme 


    const [themeKey, setthemeKey] = useState(0);

    const handleThemeSelect = useCallback(
        (value) => {
            console.log(value);
            setthemeKey(config.themeConfigs.findIndex(x => x.themeId == value));

        },
        [config],
    );



    console.log('mount... FormOnSubmitExample');
    // select process type


    useEffect(() => {


        console.log('u----------------------called');
        let didCancel = false;

        var controller = new AbortController();
        var signal = controller.signal;

        const getSetting = () => {
            console.log('fetch getSetting');

            fetch(`theme/themeconfig?apptoken=${prop.apptoken}`, { signal: signal })
                .then((response) => response.json())
                .then((data) => {
                    if (!didCancel) {
                        setpageconfig({
                            loading: false,
                            config: data,
                            processResult
                        });
                        console.log("data");
                        console.log(data);

                        setthemeKey(data.themeConfigs.findIndex(x => x.isMain));

                        console.log("index");
                        console.log(data.themeConfigs.findIndex(x => x.isMain));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };


        getSetting();
        return () => {
            didCancel = true;
        };
    }, [prop])

     

    const handleSubmit = async (_event) => {
        setpageconfig({
            loading: true, config,
            processResult
        }); 

        const response = await fetch(`theme/upserttheme?apptoken=${prop.apptoken}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(config.themeConfigs[themeKey])
        })


        console.log(result);

        if (response.status === 200) {
            var result = await response.json();
            processResult.show = true;
            processResult.detail.result = true;
            processResult.detail.message = result.responseMessage;
            console.log("change config");
           
        } else {
            processResult.show = true;
            processResult.detail.result = false;
            processResult.detail.message = JSON.stringify(result);
        } 
        config.themeConfigs = result.themeConfigs;
        setpageconfig({
            loading: false,
            config: config,
            processResult
        }); 


    };

    const handleDeleteSubmit = async (_event) => {
        setpageconfig({
            loading: true, config,
            processResult
        });

        const response = await fetch(`theme/cleancodes?apptoken=${prop.apptoken}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(config.themeConfigs[themeKey])
        })


        console.log(result);

        if (response.status === 200) {
            var result = await response.json();
            processResult.show = true;
            processResult.detail.result = true;
            processResult.detail.message = result.responseMessage;
            console.log("change config");

        } else {
            processResult.show = true;
            processResult.detail.result = false;
            processResult.detail.message = JSON.stringify(result);
        }

        setpageconfig({
            loading: false,
            config: result,
            processResult
        });

        window.scrollTo(0, 0)
    };

  
    // if add to product page

 

    const handleIsInsertToHeaderMenuToggle = useCallback(() => {


        console.log(config.themeConfigs[themeKey].isInProductCollectionLiqudid);
        console.log(themeKey);

        config.themeConfigs[themeKey].isAddMyWishlistCodeToTheme = !config.themeConfigs[themeKey].isAddMyWishlistCodeToTheme;

        console.log(config);

        setpageconfig({
            loading: false,
            config: { ...config, themeConfigs: config.themeConfigs },
            processResult
        });
         
    },
        [themeKey,config]
    );

 

    const headerFontSizeOptions = [
        { label: 'h1', value: 'h1' },
        { label: 'h2', value: 'h2' },
        { label: 'h3', value: 'h3' },
        { label: 'h4', value: 'h4' }
    ];

    const handleSelectChangeForHeaderSizeType = useCallback((value) => {

        config.themeConfigs[themeKey].myWishlistDesign.headSizeType = value;
         
        setpageconfig({
            loading: false,
            config: { ...config, themeConfigs: config.themeConfigs },
            processResult
        });
         
        console.log(config);
    },
        [themeKey, config]
    );

    const handleChangeForHeaderText = useCallback((value) => {

        config.themeConfigs[themeKey].myWishlistDesign.pageTitle = value;

        setpageconfig({
            loading: false,
            config: { ...config, themeConfigs: config.themeConfigs },
            processResult
        });

        console.log(config);
    },
        [themeKey, config]
    );


    // go to theme custom page 
    function GoToThemeCsutomPage() {

        var host = btoa(config.shopOrigin + '/admin');
        console.log("shoporigin");
        console.log(config.shopOrigin);


        const app = createApp({
            apiKey: config.apiKey,
            host: host,
        });
   
            //  Redirect.create(app).dispatch(Redirect.Action.ADMIN_PATH,permissionUrl);
               ShopifyRedirect.create(app).dispatch(ShopifyRedirect.Action.ADMIN_PATH, {
                 path: config.themeConfigs[themeKey].themeSettingUrl,
                newContext: true,
            });
             

    }


    if (!config) {
        return <><Menu /></>
    } else {

        return (
            <><Menu />
                <Page
                    title="テーマ設定">
                    <Heading>{config.themeConfigs[themeKey].themeName}</Heading>
                    <Layout>
                        <Layout.Section>
                            {processResult.show === true ?
                                <Banner title={processResult.detail.result ? "保存しました" : "保存失敗しました"} onDismiss={() => {
                                    processResult.show = false;
                                    setpageconfig({
                                        loading,
                                        config,
                                        processResult: processResult
                                    });
                                } } status={processResult.detail.result ? "success" : "critical"}>
                                    <p>{processResult.detail.message}</p>
                                </Banner>
                                : ""}
                        </Layout.Section>

                        <Layout.Section>
                            <div className="f-r">
                            <Button loading={loading} onClick={handleSubmit}>設定保存</Button>
                                 
                            </div>
                        </Layout.Section>
                         
                        <Layout.AnnotatedSection
                            id="storeDetails"
                            title="対象テーマ選択"
                            description="マイお気に入りリストのコードをテーマに追加しますと、Shopifyのエディターを使ってマイお気に入りリストのデザインを自由に変えられます。"
                        >
                            <Card sectioned>
                                <Select
                                    label="マイお気に入りリストを追加するテーマ選択"
                                    options={config.themeConfigs.map(y => ({ label: y.themeName, value: y.themeId }))}
                                    onChange={handleThemeSelect}
                                    value={config.themeConfigs.filter(x => x.themeId).map(y => y.themeId)[themeKey]}
                                    disabled={loading} />

                                <br />
                                <SettingToggle
                                    action={{
                                        content: config.themeConfigs[themeKey].isAddMyWishlistCodeToTheme ? '非表示に変更' : '表示に変更',
                                        onAction: handleIsInsertToHeaderMenuToggle,
                                        disabled: loading
                                    }}
                                    enabled={config.themeConfigs[themeKey].isAddMyWishlistCodeToTheme}
                                >
                                    マイお気に入りリストをテーマに追加:<TextStyle variation="strong">{config.themeConfigs[themeKey].isAddMyWishlistCodeToTheme ? '表示中' : '非表示中'}</TextStyle>
                                </SettingToggle>
                                <br />
                                <TextField
                                    label="マイお気に入りリストタイトル"
                                    value={config.themeConfigs[themeKey].myWishlistDesign.pageTitle}
                                    onChange={handleChangeForHeaderText}
                                    autoComplete="off" />
                                <br />

                                <Select
                                    label="マイお気に入りリストタイトルサイズ"
                                    options={headerFontSizeOptions}
                                    onChange={handleSelectChangeForHeaderSizeType}
                                    value={config.themeConfigs[themeKey].myWishlistDesign.headSizeType} />
                            </Card>
                        </Layout.AnnotatedSection>



                        <Layout.AnnotatedSection
                            id="storeDetails"
                            title="お気に入りアイコン表示"
                            description="コレクションと商品ページの表示設定はテーマカスタマイズページで設定できます。"
                        >
                            <Card sectioned>
                                <Button loading={loading} onClick={GoToThemeCsutomPage}>テーマカスタイマイズ</Button>
                                
                            </Card>
                        </Layout.AnnotatedSection>




                        <Layout.Section>
                            <div className="f-r"> 

                                <Button loading={loading} destructive={true} onClick={handleDeleteSubmit}>追加したコード全部削除</Button>
                            </div>
                        </Layout.Section>
                    </Layout>





                </Page></>
        );
    }
}
