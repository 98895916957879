import React, { Component } from 'react';
import { Route } from 'react-router';

import { Dashboard } from './components/Dashboard';
import { ThemeSetting } from './components/ThemeSetting';

import { AppProvider } from '@shopify/polaris';
import { Link as ReactRouterLink } from 'react-router-dom';
import ja from '@shopify/polaris/locales/ja.json';


import './custom.css'


const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

function Link({ children, url = '', external, ref, ...rest }) {
    // react-router only supports links to pages it can handle itself. It does not
    // support arbirary links, so anything that is not a path-based link should
    // use a reglar old `a` tag
    if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
        rest.target = '_blank';
        rest.rel = 'noopener noreferrer';
        return (
            <a href={url} {...rest}>
                {children}
            </a>
        );
    }

    return (
        <ReactRouterLink to={url} {...rest}>
            {children}
        </ReactRouterLink>
    );
}

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <AppProvider i18n={ja} linkComponent={Link}>
           
                <Route exact path='/' render={() => <Dashboard apptoken={this.props.apptoken} />} />
                <Route path='/themesetting' render={() => <ThemeSetting apptoken={this.props.apptoken} />} />
          
           </AppProvider>
        );
    }
}
