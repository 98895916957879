import React, { useCallback, useState, Component } from 'react';
import { Layout, Tabs,Link } from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';

export class Menu extends Component { 
    static displayName = Menu.name;

    //constructor(props) {
    //    super(props);

 
    //}

    TabsWithCustomDisclosureExample() {

        const tabs = [{
            id: 'dashboard',
            content: 'ダッシュボード',
            accessibilityLabel: 'top',
            panelID: 'dashboard',
            url: "/"
        },
        {
            id: 'themesetting',
            content: '表示設定',
            panelID: 'themesetting',
            url: "/themesetting"
        }
        ];

        console.log("get url");
        console.log(GetIndexFromUlr());
        console.log(window.location.pathname);

        const [selected, setSelected] = useState(GetIndexFromUlr());

        const handleTabChange = useCallback(selectedTabIndex => setSelected(selectedTabIndex), []);

        function GetIndexFromUlr() {
            var path = window.location.pathname;
            if (path.includes("themesetting")) {
                return 1;
            }
            //if (path.includes("data")) {
            //    return 3;
            //} 
            return 0;

        }
         





        return <div>
            <div className="inquery">
                <Link url="mailto:preparingforrelease@gmail.com" external>
                    メールでお問い合わせ
            </Link>
            </div>
          <div className="noborder"><Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted={false} disclosureText="More views Test">
            <Layout.Section title={tabs[selected].content}>
                <p></p>
            </Layout.Section>
          </Tabs></div></div>
            ;
}



render() {
    return (
        < this.TabsWithCustomDisclosureExample />
    )
};
}
